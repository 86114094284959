import React from "react"
import SEO from "../components/seo"
import notfound from "../assets/images/notfound.png"

const NotFoundPage = () => (
  <div>
    <SEO title="404 Not Found | Way" />
    <section className="notfound-section">
      <div className="container">
        <img style={{ maxHeight: 250 }} src={notfound} alt="" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <a href="/">Go back</a>
      </div>
    </section>
  </div>
)

export default NotFoundPage
